import React from "react";
// import image from "../assets/anup.png";
// import { FiGithub } from "react-icons/fi";
// import { CiLinkedin } from "react-icons/ci";
import Hero from "../components/Hero";
import About from "../components/About";
import Portfolio from "../components/Portfolio";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Blog from "../components/Blog";

export default function HomePage() {
  return (
    <>
      <Hero />
      <About />
      <Portfolio />
      <Blog/>
      <Contact />
      <Footer/>
    </>
  );
}
