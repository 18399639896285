import React from "react";
import Portfolio from "../components/Portfolio";
import Footer from "../components/Footer";

export default function PortfolioPage() {
  return (
    <div className="lg:pt-0 pt-10">
      <Portfolio />
      <div className="w-full lg:block hidden fixed bottom-0">
        <Footer />
      </div>
    </div>
  );
}
