import React from "react";

export default function BlogCard({ img, title, link, description }) {
  return (
    <div className="w-72 cursor-pointer hover:scale-y-95 transition-all ease duration-300">
      <a
        className=" flex flex-col gap-4"
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        <div>
          <img
            src={img}
            alt=""
            className="w-full rounded-lg shadow-2xl bg-black bg-opacity-100 border-[#f9f9f9] border-[4px]"
          />
        </div>

        <p className="text-lg font-medium px-2 overflow-hidden inline-block whitespace-nowrap max-w-72 text-ellipsis">
          🚀 {title}
        </p>
        <p className="overflow-hidden inline-block whitespace-nowrap max-w-72 text-ellipsis px-2">
          👨🏻‍💻 {description}
        </p>
      </a>
    </div>
  );
}
