import React from "react";
import About from "../components/About";
import Footer from "../components/Footer";

export default function AboutPage() {
  return (
    <div className="lg:pt-0 pt-10">
      <About />
      <div className="w-full fixed lg:block hidden bottom-0">
        <Footer />
      </div>
    </div>
  );
}
