import React, { useState, useEffect } from "react";
import boy from "../assets/me-light.png";

// import sound from "../assets/sounds/pop-up.wav";

export default function PopUp() {

  const [showPopUp, setShowPopUp] = useState(false);

  const handleClose = () => {
    
    const element = document.getElementById("slide");
    if (element) {
      element.classList.add("slide-left");
      setTimeout(() => {
        setShowPopUp(false);
      }, 500); // Adjust the timeout to match your animation duration
    }
  };

  useEffect(() => {
    const timerToShow = setTimeout(() => {
      setShowPopUp(true);
    }, 4000); // Show after 4 seconds

    const timerToHide = setTimeout(() => {
      setShowPopUp(false);
    }, 40000);

    return () => {
      clearTimeout(timerToShow);
      clearTimeout(timerToHide);
    };
  }, []);
  console.log(showPopUp);
  return (
    showPopUp && (
      <div id="slide" className={`fixed lg:bottom-20 bottom-40 z-[9999999999]`}>
        <div className="flex ">
          <img src={boy} alt="" className="w-20 h-auto pop-ip-image-shadow " />
          <div className=" text-wrap text-center p-6 flex flex-col justify-center items-center bg-[#fc7f19b8] rounded-lg  outline-white border-4 shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]">
            <div className="flex flex-col justify-center items-center gap-3 lg:gap-6">
              <span className="text-2xl text-zinc-800 font-semibold font-Caveat">
                Subscribe my newsletter !!
              </span>
              <div className="flex gap-3 ">
                <button
                  className="px-2 py-1 border bg-white shadow-lg text-zinc-700 rounded-xl lg:text-normal text-sm"
                  onClick={handleClose}
                >
                  <a
                    href="https://www.linkedin.com/build-relation/newsletter-follow?entityUrn=7155092145094168577"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Subscribe
                  </a>
                </button>
                <button
                  className="px-2 py-1 border bg-white shadow-lg text-zinc-700 rounded-xl lg:text-normal text-sm"
                  onClick={handleClose}
                >
                  No, thanks!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
