import img1 from "../assets/BlogImage/img1.png";
import img2 from "../assets/BlogImage/img2.png";
export const blogDatas = [
  {
    img: img1,
    title: "React can be worked in a small portion in our application 🚀",
    description:
      "Which element you want to be rendered in react, just do root.render(<element>)...",
    link: "https://medium.com/@anupkumarjana/react-can-be-worked-in-a-small-portion-in-our-application-71ac974c8f5a",
  },
  {
    img: img2,
    title: "Why do some imports use { } ?",
    description:
      "You might have seen some imports in React to use curly braces { }...",
    link: "https://medium.com/@anupkumarjana/why-do-some-imports-use-d194c00cb391",
  },
  {
    img: img2,
    title: "Why do some imports use { } ?",
    description:
      "You might have seen some imports in React to use curly braces { }...",
    link: "https://medium.com/@anupkumarjana/why-do-some-imports-use-d194c00cb391",
  },
  {
    img: img2,
    title: "Why do some imports use { } ?",
    description:
      "You might have seen some imports in React to use curly braces { }...",
    link: "https://medium.com/@anupkumarjana/why-do-some-imports-use-d194c00cb391",
  },
  {
    img: img2,
    title: "Why do some imports use { } ?",
    description:
      "You might have seen some imports in React to use curly braces { }...",
    link: "https://medium.com/@anupkumarjana/why-do-some-imports-use-d194c00cb391",
  },

];
