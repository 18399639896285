import React from "react";
import { GrMapLocation } from "react-icons/gr";
import { LuMail } from "react-icons/lu";
import { FaLinkedin } from "react-icons/fa";
import "../custom.css";

export default function Contact() {
  return (
    <div className="w-full  bg-[#f9f9f9]">
      <div
        data-aos="fade-up"
        className="text-[#2d2e32]  pb-16 px-8 py-16 md:px-60 md:py-32  gap-20 font-poppins"
        id="contact"
      >
        <div className="md:flex flex-col md:items-start md:justify-start flex justify-center items-center">
          <h2 className="uppercase text-xl font-extrabold text-yellow-500 pb-4">
            Contact
          </h2>
          <p className="font-poppins text-2xl font-extrabold text-[#2D2E32] pb-4">
            Don't be shy! Hit me up! 👇
          </p>
        </div>
        <div className="md:mt-16 mt-10 md:flex flex-col md:flex-row gap-40">
          <div className="md:flex md:flex-row flex-col gap-8 flex justify-center items-center">
            <button className="text-4xl rounded-full shadow-[0_8px_30px_rgb(0,0,0,0.12)] text-yellow-500 p-2">
              <GrMapLocation />
            </button>
            <div className="flex flex-col justify-center md:items-start items-center">
              <p className="font-semibold text-lg">Location</p>
              <a
                className="hover:text-yellow-500"
                href="https://www.google.com/maps/d/u/0/viewer?mid=1jjdOnB91E1dK8dHGVI3h8cHWE40&hl=en_US&ll=13.072006049550708%2C77.63024850000004&z=11"
                target="blank"
              >
                Bangalore, India
              </a>
            </div>
          </div>
          <div className="md:flex md:flex-row flex-col gap-8 flex justify-center items-center mt-10 md:mt-0">
            <button className="text-4xl rounded-full shadow-[0_8px_30px_rgb(0,0,0,0.12)] text-yellow-500 p-2">
              <LuMail />
            </button>
            <div className="flex flex-col justify-center md:items-start items-center cursor-pointer ">
              <p className="font-semibold text-lg">Mail</p>
              <a
                className="hover:text-yellow-500"
                href="https://mail.google.com/mail/u/0/#inbox?compose=CllgCJqSvxwKNnBCHGFckrRPRcQKNvPblTBGpHbWrQQxHhHNdCjMbpSqDshPHWqZgBgtlHgcfNq"
                target="_blank"
                rel="noreferrer"
              >
                janaanup7872@gmail.com
              </a>
            </div>
          </div>
        </div>
        <div className="flex lg:justify-start justify-center items-center">
          <button className="flex flex-row items-center gap-4 border rounded-lg mt-20 px-10 py-2 shadow-xl bg-white hover:-translate-y-2 transition-all ease duration-300 hover:scale-y-95 font-semibold">
            <a
              className="flex flex-row items-center"
              href="https://www.linkedin.com/build-relation/newsletter-follow?entityUrn=7155092145094168577"
              target="_blank"
              rel="noreferrer"
            >
              Subscribe My Newsletter
            </a>
            <span className="text-3xl text-[#0077b5]">
              <FaLinkedin />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
