import React, { useState } from "react";
import BlogCard from "./BlogCard";
import { blogDatas } from "../api/BlogData";

export default function Blog() {
  const [isClicked, setIsClicked] = useState(false);

  function toggleClick() {
    setIsClicked(!isClicked);
  }

  const visibleBlogs = isClicked ? blogDatas : blogDatas.slice(0, 3);
  return (
    <div
      className="bg-[#ffffff] text-[#2d2e32] w-full pb-16 px-8 py-16 md:px-60 md:py-32  gap-20 font-poppins"
      id="blogs"
    >
      <div className="md:flex md:justify-normal md:items-start flex flex-col justify-center items-center relative">
        <h2 className="uppercase  text-xl font-extrabold text-yellow-500 pb-4 text-center md:text-start">
          Blogs
        </h2>
        <h3 className="font-poppins text-2xl font-extrabold text-[#2D2E32] pb-4 text-center md:text-start">
          Read out my technical blogs 👨🏻‍💻
        </h3>
        <div
          data-aos="fade-up"
          className="flex gap-10 lg:justify-start justify-center items-center mt-10 flex-wrap object-cover pb-16 "
        >
          {visibleBlogs.map((blogData, index) => (
            <BlogCard key={index} {...blogData} />
          ))}
        </div>
        <button
          className="absolute bottom-0 right-0 pt-10"
          onClick={toggleClick}
        >
          {isClicked ? "See less" : "See more"}
        </button>
      </div>
    </div>
  );
}
