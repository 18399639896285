import React from "react";
import Blog from "../components/Blog";
import Footer from "../components/Footer";

export default function BlogPage() {
  return (
    <div className="lg:pt-0 pt-10">
      <Blog />
      <div className="w-full fixed lg:block hidden bottom-0">
        <Footer />
      </div>
    </div>
  );
}
