import React from "react";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

export default function ContactPage() {
  return (
    <div className="lg:pt-0 pt-10 ">
      <Contact />

      <div className="w-full lg:block hidden fixed bottom-0">
        <Footer />
      </div>
    </div>
  );
}
